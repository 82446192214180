import {React,useContext,useEffect} from 'react'
import Button from '../Button/Button'
import './Header.scss'
import { Link,useNavigate } from 'react-router-dom';
import { EssentialContext } from '../../Assets/EssentialContext';


export default function Header() {
    const navigate = useNavigate()
    const [essential,setEssential] = useContext(EssentialContext)
   

    useEffect(() => {
     essential.checkUser(navigate)
    }, [])

    return (
        <header className="header">
            <Button 
                
                children="Logout" 
                icon="logout"
                type="btn-danger"
                link="/logout"
            />

          
        </header>
    )
}
