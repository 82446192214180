import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';



export default function Toast({open,setOpen,msg}) {
  const [transition, setTransition] = React.useState(undefined);
  const handleClose = () => {
    setOpen(false);
  };
  return (
      <Snackbar
        open={open}
        ContentProps={{
          sx: {
            background: msg.status === "success" || msg.status === "Success" ? "green":"#d51111"
          }
        }}
        onClose={handleClose}
        TransitionComponent={transition}
        message={<p>{msg.message}</p>}
        autoHideDuration={3000}
        key={transition ? transition.name : ''}
      />
  );
}