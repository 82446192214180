import React from 'react'
import './StudentProfile.scss'

export default function StudentProfile() {
  return (
    <section className="student-profile">
        <h1 className="section-title">Student Profile</h1>
        <form className="details-container">
            <div className="input-wrapper">
                <label htmlFor='studentName'>Name</label>
                <input type="text" name='studentName'  className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='schoolName'>School Name</label>
                <input type="text" name='schoolName' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='mobileNo'>Mobile No</label>
                <input type="text" name='mobileNo' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='address'>Address</label>
                <input type="text" name='address' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='percentage'>Percentage</label>
                <input type="text" name='percentage' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='email'>Email</label>
                <input type="text" name='email' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='guardianName'>Guardian Name</label>
                <input type="text" name='guardianName' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='guardianRelation'>Guardian Relationship</label>
                <input type="text" name='guardianRelation' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='guardianEmail'>Guardian Email</label>
                <input type="text" name='guardianEmail' disabled className="student-input" />
            </div>
            <div className="input-wrapper">
                <label htmlFor='guardianMobile'>Guardian Mobile</label>
                <input type="text" name='guardianMobile' disabled className="student-input" />
            </div>
        </form>
    </section>
  )
}
