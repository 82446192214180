import React  from 'react'
import './Popup.scss'
import {AiOutlineClose} from 'react-icons/ai'

export default function Popup({ children ,heading,refs,close}) {


    return (
        <div className="popup_container" ref={refs} >
            <div className="popup_content">
            <div className="popup_header">
        <h2>{heading}</h2>
        <AiOutlineClose onClick={close} className="btn-close"/>
        </div>
                {children}
            </div>
        </div>
    )
}
