import { EssentialProvider } from "./Assets/EssentialContext";
import AdminPanel from "./Components/AdminPanel";

function App() {
  return (
    <EssentialProvider>
      <AdminPanel />
    </EssentialProvider>
  );
}

export default App;
