import React, { useEffect, useRef, useState } from "react";
import "./TeacherApplication.scss";
import { TiTick } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";
import Toast from "../Toast/Toast";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { sample } from "lodash";
import instance_url from "../../Assets/instance";
ChartJS.register(ArcElement, Tooltip, Legend);
export default function TeacherApplication() {
  const [data, setData] = useState([]);
  const [scheduleData, setScheduleData] = useState();
  const [open, setOpen] = useState(false);
  const [tmsg, setTmsg] = useState({});
  const [teacher, setTeacher] = useState();
  const [filterValue, setFilteredValue] = useState(-1);
  const [showEmptyClass, setShowEmptyClass] = useState([false, "loading..."]);
  const [showEmptyHour, setShowEmptyHour] = useState([false, "loading..."]);
  const [showEmptyExpense, setShowEmptyExpense] = useState([
    false,
    "loading...",
  ]);

  const dummyData = {
    labels: ["Empty", "Empty"],
    datasets: [
      {
        label: "No Data",
        data: [0, 0],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(153, 102, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const [hours, setHours] = useState(dummyData);
  const [expense, setExpense] = useState(dummyData);
  const [delClasses, setDelClasses] = useState(dummyData);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const fromDateRef = useRef();
  const toDateRef = useRef();
  let page = window.location.pathname;
  const popupRef = useRef();

  const getverifiedTeacher = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teacher`);
    const data = await response.json();
    setData(data.data);
  };

  const deleteTeacher = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/teacher/delete/${data.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === "OK") {
      setOpen(true);
      setTmsg({ status: "success", message: res.message });
      getverifiedTeacher();
    } else {
      setOpen(true);
      setTmsg({
        status: "failed",
        message: res.message || "something went to wrong",
      });
    }
  };

  const scheduleInterview = async (data) => {
    if (data.time) {
      var timesplit = data.time.split(":"),
        hour = timesplit[0],
        minute = timesplit[1];

      var Time =
        (hour % 12) +
        12 * (hour % 12 == 0) +
        ":" +
        minute +
        " " +
        (hour < 12 ? "AM" : "PM");
    }

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/teacher/schedule-interview`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id,
          time: Time,
          date: data.date,
          meet: data.meet,
        }),
      }
    );
    const res = await response.json();
    if (res.status === "OK") {
      setScheduleData({
        id: "",
        time: "",
        date: "",
        meet: "",
      });
      setOpen(true);
      setTmsg({ status: "success", message: res.message });

      getverifiedTeacher();
    } else {
      setOpen(true);
      setTmsg({
        status: "failed",
        message: res.message || "something went to wrong",
      });
    }
  };
  const handleApplicationStatus = async (item, method) => {
    if (method === "approve") {
      popupRef.current.classList.add("popup--active");
      setScheduleData({
        id: item.id,
        time: "",
        meet: "",
        date: "",
      });
    } else if (method === "deny") {
      if (window.confirm("Are you sure to Reject this Teacher ?")) {
        deleteTeacher(item);
      }
    } else if (method === "view") {
      popupRef.current.classList.add("popup--active");
      setTeacher(item);
      console.log(item, "item");
      console.log(teacher);
    }
  };

  const closePopup = () => {
    popupRef.current.classList.remove("popup--active");
  };

  const handleScheduleData = (event) => {
    setScheduleData({
      ...scheduleData,
      [event.target.name]: event.target.value,
    });
  };

  const handleScheduling = (event) => {
    event.preventDefault();

    if (
      scheduleData.time.length > 2 &&
      scheduleData.date.length > 2 &&
      scheduleData.meet.length > 5
    ) {
      scheduleInterview(scheduleData);
      closePopup();
    } else {
      setOpen(true);
      setTmsg({ status: "failed", message: "Please fill all the fields" });
    }
  };
  ////chart api calling  start

  useEffect(() => {
    console.log(typeof filterValue);
    if (filterValue == "-5") {
    } else {
        console.log("calling...");
      getHours(filterValue);
      getPayments(filterValue);
      getDelClasses(filterValue);
    }
  }, [filterValue]);

  const getDelClasses = () => {
    let url =
      instance_url +
      `/classes/status-details?id=${filterValue}&teacher=${teacher?.id}`;
    if (filterValue == -1) {
      url = instance_url + `/classes/status-details?teacher=${teacher?.id}`;
    } else if (filterValue == -2) {
      let fromdates = fromDate?.split("-");
      let todates = toDate?.split("-");
      url =
        instance_url +
        `/classes/status-details?teacher=${teacher?.id}&id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`;
    }
    setShowEmptyClass([true, "loading..."]);
    axios
      .get(url)
      .then((res) => {
        let result = res.data.Classdetails[0];
        let data = {};
        if (result) {
          setShowEmptyClass([false, ""]);
          data = {
            labels: ["Delivered Classes", "Cancelled classes"],
            datasets: [
              {
                label: "# of Votes",
                data: [result.attended, result.cancelled],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyClass([true, "No Data"]);
          data = {
            labels: ["Delivered Classes", "Cancelled classes"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
        }
        setDelClasses(data);
      })
      .catch((err) => {});
  };

  const getHours = (filterValue) => {
    let url =
      instance_url + `/teacher/hours?id=${filterValue}&teacher=${teacher?.id}`;
    if (filterValue == -1) {
      url = instance_url + `/teacher/hours?teacher=${teacher?.id}`;
    } else if (filterValue == -2) {
      let fromdates = fromDate?.split("-");
      let todates = toDate?.split("-");
      url =
        instance_url +
        `/teacher/hours?teacher=${teacher?.id}&id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`;
    }
    setShowEmptyHour([true, "loading..."]);
    axios
      .get(url)
      .then((res) => {
        let result = res.data.hours[0];
        let data = {};
        if (result) {
          setShowEmptyHour([false, ""]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo class"],
            datasets: [
              {
                label: "# of Votes",
                data: [
                  result.onetoone,
                  result.onetoSix || result.onetosix,
                  result.onetomany,
                  result.democlass,
                ],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyHour([true, "No Data"]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo class"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0, 0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        }

        setHours(data);
      })
      .catch((err) => {});
  };

  const getPayments = (token) => {
    let url =
      instance_url +
      `/teacher/expense?id=${filterValue}&teacher=${teacher?.id}`;
    if (filterValue == -1) {
      url = instance_url + `/teacher/expense?teacher=${teacher?.id}`;
    } else if (filterValue == -2) {
      let fromdates = fromDate?.split("-");
      let todates = toDate?.split("-");
      url =
        instance_url +
        `/teacher/expense?teacher=${teacher?.id}&id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`;
    }
    axios
      .get(url)
      .then((res) => {
        let result = res.data.teacherExpense[0];
        setShowEmptyExpense([true, "loading..."]);
        let data = {};
        if (result) {
          setShowEmptyExpense([false, ""]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo class"],
            datasets: [
              {
                label: "# of Votes",
                data: [
                  result.onesalary,
                  result.sixsalary,
                  result.manysalary,
                  result.demosalary,
                ],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyExpense([true, "No Data"]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo class"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0, 0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        }

        setExpense(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getverifiedTeacher();
  }, [teacher]);

  useEffect(() => {
    if(filterValue!= -5 ){
        getHours(filterValue);
        getPayments(filterValue);
        getDelClasses(filterValue);
    }
  }, [filterValue, teacher]);
  const customDateHandler = () => {
    if (fromDateRef.current.value && toDateRef.current.value) {
      setFromDate(fromDateRef.current.value);
      setToDate(toDateRef.current.value);
      setFilteredValue(-2);
    } else {
      console.log("no data");
    }
  };
  return (
    <section className="teacher-application-section">
      <Toast open={open} msg={tmsg} setOpen={setOpen} />
      <h1 className="section-title">
        {page !== "/admin-panel/teachers" ? "Teacher Applications" : "Teachers"}
      </h1>
      <table className="statics-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>ZoomID</th>
            <th>Subjects</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) =>
              item.isVerified ===
              (page === "/admin-panel/teachers" ? true : 0) ? (
                <tr key={index} className="rows">
                  <td>{item.name}</td>
                  <td>{item.mobile}</td>
                  <td>{item.email}</td>
                  <td>{item.zoomid}</td>
                  <td>
                    {item.subjects.map((subject, index) => (
                      <span key={index}>{subject} </span>
                    ))}
                  </td>
                  {page !== "/admin-panel/teachers" ? (
                    <td>
                      <button
                        className="deny"
                        onClick={() => handleApplicationStatus(item, "deny")}
                      >
                        <AiOutlineClose />
                      </button>

                      <button
                        className="approve"
                        onClick={() => handleApplicationStatus(item, "approve")}
                      >
                        <TiTick />
                      </button>
                    </td>
                  ) : (
                    <td>
                      <button
                        className="approve"
                        onClick={() => handleApplicationStatus(item, "view")}
                      >
                        View
                      </button>
                    </td>
                  )}
                </tr>
              ) : null
            )}
        </tbody>
      </table>
      <div className="teacher-accept-popup-container" ref={popupRef}>
        <div className="teacher-accept-popup">
          <button className="btn-close" onClick={closePopup}>
            <AiOutlineClose />
          </button>
          <h2 className="popup-title">
            {page !== "/admin-panel/teachers"
              ? "Schedule Meeting"
              : teacher?.name}
          </h2>
          {page !== "/admin-panel/teachers" ? (
            <form className="schedule-form" onSubmit={handleScheduling}>
              <label htmlFor="meetLink">Meet Link</label>
              <input
                type="text"
                className="schedule-input"
                name="meet"
                onChange={handleScheduleData}
              />
              <label htmlFor="date">Date</label>
              <input
                type="date"
                className="schedule-input"
                name="date"
                onChange={handleScheduleData}
              />
              <label htmlFor="time">Time</label>
              <input
                type="time"
                className="schedule-input"
                name="time"
                onChange={handleScheduleData}
              />
              <button type="submit" className="btn-schedule">
                Schedule
              </button>
            </form>
          ) : (
            <div className="teacher-info-container">
              <div className="teacher-info-items">
                <label>subjects:-</label>
                {teacher?.subjects.map((subject, index) => (
                  <label key={index}>{subject} </label>
                ))}
              </div>

              <div className="teacher-info-items">
                {filterValue == -5 && (
                  <div className="custom-date-box">
                    <h3>From :</h3>
                    <input
                      ref={fromDateRef}
                      type="date"
                      name=""
                      id=""
                      className="from"
                    />
                    <h3>To :</h3>
                    <input
                      ref={toDateRef}
                      type="date"
                      name=""
                      id=""
                      className="to"
                    />
                    <button onClick={customDateHandler}>Search</button>
                  </div>
                )}
                <label>Email:-</label>
                <label>{teacher?.email} </label>
                <div className="filter-wrapper">
                  <select
                    value={filterValue}
                    onChange={(e) => setFilteredValue(e.target.value)}
                    name=""
                    id=""
                  >
                    <option value={-1}>Select a filter</option>
                    <option value={0}>today</option>
                    <option value={1}>Last 7 days</option>
                    <option value={2}>Last 30 days</option>
                    <option value={3}>Last 90 days</option>
                    <option value={4}>Last year</option>
                    <option value={-5}>custom</option>
                  </select>
                </div>
              </div>

              <div className="graph-statistics-wrapper">
                {expense && (
                  <div className="graph-card">
                    <h2 className="graph-title">Payment Volume</h2>
                    <div className="chart">
                      {showEmptyExpense[0] ? (
                        showEmptyExpense[1]
                      ) : (
                        <Pie data={expense} />
                      )}
                    </div>
                  </div>
                )}
                {hours && (
                  <div className="graph-card">
                    <h2 className="graph-title">Number of Hours</h2>
                    <div className="chart">
                      {showEmptyHour[0] ? (
                        showEmptyHour[1]
                      ) : (
                        <Pie data={hours} />
                      )}
                    </div>
                  </div>
                )}
                {delClasses && (
                  <div className="graph-card">
                    <h2 className="graph-title">Class statistic</h2>
                    <div className="chart">
                      {showEmptyClass[0] ? (
                        showEmptyClass[1]
                      ) : (
                        <Pie data={delClasses} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
