import { React, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Header from './Header/Header';
import { AdminServices } from '../utils/Services'
import Navbar from './Navbar/Navbar';
import './AdminPanel.scss'
import Blog from './Blog/Blog';
import CreateBlog from './CreateBlog/CreateBlog';
import Login from './Login/Login';
import Trialclass from './TrialClass/Trialclass';
import Dashboard from './Dashboard/Dashboard';
import TeacherApplication from './TeacherApplication/TeacherApplication';
import ScheduledMeetings from './Scheduled Meetings/ScheduledMeetings';
import CreateClass from './CreateClass/CreateClass';
import Students from './Students/Students';
import StudentProfile from './StudentProfile/StudentProfile';

export default function AdminPanel() {
    const [teachers, setTeachers] = useState([])
    const [students, setStudents] = useState([])
    const [classes, setClasses] = useState([])
    const [loading,setLoading] = useState(false)
    const [callAgain,setCallAgain] = useState(false)

    const getTeachers = async () => {
        setLoading(true)
        const teachers = await fetch(`${process.env.REACT_APP_BASE_URL}/teacher/verified`)
        const data = await teachers.json()
        setTeachers(data.data)
        setLoading(false)
    }

    const getStudents = async () => {
        setLoading(true)
        const students = await fetch(`${process.env.REACT_APP_BASE_URL}/student`)
        const data = await students.json()
        setStudents(data.student)
        setLoading(false)
    }

    const getClasses = async () => {
        setLoading(true)
        const classes = await fetch(`${process.env.REACT_APP_BASE_URL}/classes`)
        const data = await classes.json()
        setClasses(data.classes)
        setLoading(false)
    }

    useEffect(() => {
        getTeachers()
        getStudents()
        getClasses()
    }, [callAgain])
    return (
        <main className="admin-panel">
            <Router>
                <Routes>
                    <Route path="/" exact element={<Navigate to='admin-panel/blog' />} />
                    <Route path="/admin-panel" element={
                        <>
                            <Header />
                            <Navbar />
                            <Outlet />
                        </>
                    }>
                        <Route path="blog" element={<Blog />} />
                        <Route path="blog/create" element={<CreateBlog />} />
                        <Route path="blog/edit/:id" element={<CreateBlog />} />
                        <Route path="trial-class" element={<Trialclass />} />
                        <Route path="new-classroom" element={<CreateClass setLoading={setLoading} loading={loading} teachers={teachers} students={students} classes={classes} />} />
                        <Route path="dashboard" element={<Dashboard/>} />
                        <Route path='teacher-application' element={<TeacherApplication/>} />
                        <Route path='teachers' element={<TeacherApplication/>} />
                        <Route path="scheduled-meeting" element={<ScheduledMeetings callAgain={callAgain} setCallAgain={setCallAgain} /> } />
                        <Route path='students' element={<Students/>}/>
                        <Route path='student/id' element={<StudentProfile/>} />
                        {/* <Route path="new-classroom" element={<CreateClass callAgain={callAgain} setCallAgain={setCallAgain} teachers={teachers} students={students} classes={classes} />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path='teacher-application' element={<TeacherApplication />} />
                        <Route path='teachers' element={<TeacherApplication />} />
                        <Route path="scheduled-meeting" element={<ScheduledMeetings />} />
                        <Route path="students" element={<Students />} /> */}
                    </Route>
                    <Route path="/revamp-adminiq-panel" element={<Login />} />
                </Routes>
            </Router>
        </main>
    )
}