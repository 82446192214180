import React from 'react'
import './NavLinkButton.scss';
import { NavLink } from 'react-router-dom';

export default function NavLinkButton({children, link}) {
    const checkActive = (match,location) => {
        console.log(match);
        console.log("location",location);
    }
    return (
        <NavLink to={link} style={({isActive}) => {
            return{
                backgroundColor: isActive ? "#0D1117" : ''
            }
        }} className="nav-link-btn" isActive={checkActive}> 
            {children}
        </NavLink>
    )
}
