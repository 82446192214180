import React from 'react'
import './Message.scss'

export default function Message({message}) {
    return (
        <p className="message-notification">
            {message}
        </p>
    )
}
