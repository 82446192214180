import React, { useContext } from 'react'
import './Button.scss'
import { FiLogOut } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router'
import axios from 'axios'
import instance_url from '../../Assets/instance'
import { EssentialContext } from '../../Assets/EssentialContext'
import storage from "../../Assets/firebaseConfig";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";


export default function Button({ children, type, icon, _id, image }) {
    const navigate = useNavigate();
    const [essential, setEssential] = useContext(EssentialContext)

    const handleBtnClick = () => {
        switch (icon) {
            case "logout": 
                essential.logOut(navigate);
                break;
            case "edit":
                navigate(`/admin-panel/blog/edit/${_id}`)
                break;
            case "delete":
                deleteBlog();
                break;
            case "create":
                navigate('/admin-panel/blog/create')
                break;
            default:
                break;

        }
    }

    const deleteBlog = () => {
        const confirmed = window.confirm("Are you sure to delete the blog?")
        const authorizationToken = localStorage.getItem('token');
        const deleteRef = ref(storage,'images/'+image.image_name)
        if (confirmed) {
            deleteObject(deleteRef).then(() => {
                console.log(_id);
                axios.delete(`${instance_url}/blog?blogId=${_id}`, {
                    headers:{
                        'Authorization':`Bearer ${authorizationToken}`
                    },
                }).then(res => {
                    setEssential({ ...essential, removeCard: _id, notificationMessage: res.data.message })
                    setTimeout(() => {
                        setEssential({ ...essential, removeCard: _id, notificationMessage: null })
                    }, 3000)
                }).catch(err => {
                    console.log(err);
                })
            })
            
        }

    }
    return (
        <button onClick={handleBtnClick} className={"btn btn-main " + type}>
            {
                icon === "logout" ?
                    <FiLogOut />
                    : (icon === "delete") ?
                        <MdDelete />
                        : (icon === "edit") ?
                            <RiEdit2Fill />
                            : null
            }
            {children}
        </button>
    )
}
