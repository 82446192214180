import React from 'react'
import './Loading.scss'
import loading from '../../Assets/img/loadingblack.gif'

const Loading = () => {
  return (
    <div className='loading-comp'>
        <img src={loading} alt="" />
    </div>
  )
}

export default Loading