import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./Dashboard.scss";
import axios from "axios";
import instance_url from "../../Assets/instance";
import { filter } from "lodash";
ChartJS.register(ArcElement, Tooltip, Legend);
export default function Dashboard() {
  const [hours, setHours] = useState(null);
  const [expense, setExpense] = useState(null);
  const [delClasses, setDelClasses] = useState(null);
  const [filterValue, setFilteredValue] = useState(-1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showEmptyClass, setShowEmptyClass] = useState([false, "loading..."]);
  const [showEmptyHour, setShowEmptyHour] = useState([false, "loading..."]);
  const [showEmptyExpense, setShowEmptyExpense] = useState([
    false,
    "loading...",
  ]);
  const fromDateRef  = useRef()
  const toDateRef  = useRef()

  // const data2 = {
  //   labels: ['Delivered Classes', 'Cancelled classes'],
  //   datasets: [
  //     {
  //       label: '# of Votes',
  //       data: [100, 120],
  //       backgroundColor: [
  //         'rgba(54, 162, 235, 0.2)',
  //         'rgba(153, 102, 255, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(153, 102, 255, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  useEffect(() => {
    if (filterValue != -5) {
      getHours(filterValue);
      getPayments(filterValue);
      getDelClasses(filterValue);
    }
  }, [filterValue]);

  const getDelClasses = () => {
    let url = instance_url + `/classes/status-details/all?id=${filterValue}`;
    if (filterValue == -1) {
      url = instance_url + "/classes/status-details/all";
    }
    else if(filterValue == -2 ){
      let fromdates = fromDate?.split("-")
      console.log(fromDate,"time")
      let todates = toDate?.split("-")
      url = instance_url + `/classes/status-details/all?id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`
    }
    setShowEmptyClass([true, "loading..."]);
    axios
      .get(url)
      .then((res) => {
        let result = res.data.Classdetails[0];
        let data = {};
        if (result) {
          setShowEmptyClass([false, ""]);
          data = {
            labels: ["Delivered Classes", "Cancelled classes"],
            datasets: [
              {
                label: "# of Votes",
                data: [result.attended, result.cancelled],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyClass([true, "No Data"]);
          data = {
            labels: ["Delivered Classes", "Cancelled classes"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
        }
        setDelClasses(data);
      })
      .catch((err) => {});
  };

  const getHours = (filterValue) => {
    let url = instance_url + `/teacher/hours/all?id=${filterValue}`;
    if (filterValue == -1) {
      url = instance_url + "/teacher/hours/all";
    }
    else if(filterValue == -2 ){
      let fromdates = fromDate?.split("-")
      let todates = toDate?.split("-")
      url = instance_url + `/teacher/hours/all?id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`
    }
    setShowEmptyHour([true, "loading..."]);
    axios
      .get(url)
      .then((res) => {
        let result = res.data.hours[0];
        let data = {};
        if (result) {
          setShowEmptyHour([false, ""]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo Class"],
            datasets: [
              {
                label: "# of Votes",
                data: [
                  result.onetoone,
                  result.onetoSix,
                  result.onetomany,
                  result.democlass,
                ],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyHour([true, "No Data"]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo Class"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0, 0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        }

        setHours(data);
      })
      .catch((err) => {});
  };

  const getPayments = () => {
    let url = instance_url + `/teacher/expense/all?id=${filterValue}`;
    if (filterValue == -1) {
      url = instance_url + "/teacher/expense/all";
    }
    else if(filterValue == -2 ){
      console.log(fromDate,toDate);
      let fromdates = fromDate?.split("-")
      let todates = toDate?.split("-")
      console.log(fromdates,todates);
      url = instance_url + `/teacher/expense/all?id=${-2}&from=${fromdates[0]}/${fromdates[1]}/${fromdates[2]}&to=${todates[0]}/${todates[1]}/${todates[2]}`
    }
    axios
      .get(url)
      .then((res) => {
        let result = res.data.TotalExpenses[0];
        setShowEmptyExpense([true, "loading..."]);
        let data = {};
        if (result) {
          setShowEmptyExpense([false, ""]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo Class"],
            datasets: [
              {
                label: "# of Votes",
                data: [
                  result.totalone,
                  result.totalsix,
                  result.totalmany,
                  result.totaldemo,
                ],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        } else {
          setShowEmptyExpense([true, "No Data"]);
          data = {
            labels: ["One to one", "One to six", "One to many", "Demo class"],
            datasets: [
              {
                label: "# of Votes",
                data: [0, 0, 0, 0],
                backgroundColor: [
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  " #ff4b33 ",
                ],
                borderColor: [
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(153, 102, 255, 1)",
                  " #ff4b33 ",
                ],
                borderWidth: 1,
              },
            ],
          };
        }

        setExpense(data);
      })
      .catch((err) => {});
  };
const customDateHandler = ()=>{
  if(fromDateRef.current.value && toDateRef.current.value){
    setFromDate(fromDateRef.current.value);
    setToDate(toDateRef.current.value);
    setFilteredValue(-2)
  }else{
    console.log("no data");
  }
}
  return (
    <section className="dashboard-section">
      {filterValue == -5 && 
      <div className="custom-date-box">
        <h3>From :</h3>
        <input ref={fromDateRef} type="date" name="" id="" className="from" />
        <h3>To :</h3>
        <input ref={toDateRef} type="date" name="" id="" className="to" />
        <button onClick={customDateHandler}>Search</button>
        </div>}
      <div className="filter-wrapper">
        <select
          onChange={(e) => setFilteredValue(e.target.value)}
          value={filterValue}
          name=""
          id=""
        >
          <option value={-1}>Select a filter</option>
          <option value={0}>today</option>
          <option value={1}>Last 7 days</option>
          <option value={2}>Last 30 days</option>
          <option value={3}>Last 90 days</option>
          <option value={4}>Last year</option>
          <option value={-5}>custom</option>
        </select>
      </div>
      <div className="graph-statistics-wrapper">
        {expense && (
          <div className="graph-card">
            <h2 className="graph-title">Payment Volume</h2>
            <div className="chart">
              {showEmptyExpense[0] ? (
                showEmptyExpense[1]
              ) : (
                <Pie data={expense} />
              )}
            </div>
          </div>
        )}
        {hours && (
          <div className="graph-card">
            <h2 className="graph-title">Number of Hours</h2>
            <div className="chart">
              {showEmptyHour[0] ? showEmptyHour[1] : <Pie data={hours} />}
            </div>
          </div>
        )}
        {delClasses && (
          <div className="graph-card">
            <h2 className="graph-title">Class statistic</h2>
            <div className="chart">
              {showEmptyClass[0] ? (
                showEmptyClass[1]
              ) : (
                <Pie data={delClasses} />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
