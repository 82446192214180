import React, { useEffect } from 'react'
import './Navbar.scss'
import NavLink from './NavLinks/NavLinkButton'

export default function Navbar() {

    let navItems = [
        {
            item: 'Dashboard',
            link: "/admin-panel/dashboard"
        },
        {
            item: 'Blogs',
            link: "/admin-panel/blog"
        },
        {
            item: 'Trialclass',
            link: "/admin-panel/trial-class"
        },
        {
            item: 'Teacher Applications',
            link: "/admin-panel/teacher-application"
        },
        {
            item: 'Scheduled Meeting',
            link: "/admin-panel/scheduled-meeting"
        },
        {
            item: 'Teachers',
            link: "/admin-panel/teachers"
        },
        {
            item: 'Students',
            link: "/admin-panel/students"
        },
        {
            item: 'Create Class',
            link: "/admin-panel/new-classroom"
        },
    ]
    // useEffect(() => {
    // },[])
    return (
        <aside className="aside-nav">
            {
                navItems&&
                navItems.map((item,index) => (
                    <NavLink  
                        key={index}
                        children={item.item}
                        link={item.link}
                    />
                ))
            }

            {/* <NavLink  
                children="Trial Class"
                link="/admin-panel/trial-class"
            />           */}
        </aside>
    )
}
