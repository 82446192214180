import { useState } from "react";
import { useRef } from "react";
import Popup from "../Globals/Popup/Popup";
import "./CreateClass.scss";
import Room from "./Room/Room";
import Toast from "../Toast/Toast";
import Loading from "../LoadingComp/Loading";

export default function CreateClass({
  loading,
  setLoading,
  callAgain,
  teachers,
  students,
  classes,
  setCallAgain,
}) {
  const [isId, setIsId] = useState(false);
  const [classData, setClassData] = useState({});
  const [data, setData] = useState({
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [tmsg, setTmsg] = useState({});
  const [editmode, setEditmode] = useState(false);
  const typeRef = useRef();
  const dateRef = useRef();
  const timeRef = useRef();
  const subjectsRef = useRef();
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const popupRef = useRef();
  const labelRef = useRef();

  var subject = [  "Chemistry",
    "Physics",
    "Mathematics",
    "Biology",
    "Economics",
    "Accounts",
    "Bussiness Studies",
    "Arabic",
    "French",
    "Hindi",
    "Computer science",
    "Malayalam",
    "English",]
console.log(subject);

  const popupAir = (action, id) => {
    setLoading(true);
    console.log(action);
    if (action === "create") {
      setIsId(false);
      popupRef.current.classList.add("active");
    }
    if (id) {
      setIsId(true);
      fetch(`${process.env.REACT_APP_BASE_URL}/classes/details?id=${id}`)
        .then((res) => res.json())
        .then((data) => {
          setClassData(data.classes[0]);
        });
      console.log(id);
    }
    setLoading(false);
  };
  console.log(classData);

  const popupDown = () => {
    popupRef.current.classList.remove("active");
    setEditmode(false);
  };

  const handleSubmit = async (e, classdata) => {
    e.preventDefault();
    let time = timeRef.current.value;
    if (time) {
      var timesplit = time.split(":"),
        hour = timesplit[0],
        minute = timesplit[1];
      console.log(hour, minute);
      var Time =
        (hour % 12) +
        12 * (hour % 12 == 0) +
        ":" +
        minute +
        " " +
        (hour < 12 ? "AM" : "PM");
      console.log(Time);
    }
    const date = dateRef.current.valueAsDate;
    const d = new Date(date);
    const dates = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    const data = {
      type: typeRef.current.value,
      subject: subjectsRef.current.value,
      schedule: {
        date: dates,
        time: Time,
      },
      label: labelRef.current.value,
    };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/classes/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const jsonData = await response.json();
    setLoading(false);
    if (response.status === 200) {
      setOpen(true);
      setTmsg({ status: "Success", message: jsonData.status });
      popupDown();
      // setCallAgain(true)
      window.location.reload();
    } else {
      console.log(jsonData, "res");
      setOpen(true);
      setTmsg({
        status: "Failed",
        message: jsonData.status || "something went to wrong",
      });
    }
    popupDown();
  };

  const updateSchedule = async () => {
    let time = timeRef.current.value;
    if (time && dateRef.current.value) {
      if (time) {
        var timesplit = time.split(":"),
          hour = timesplit[0],
          minute = timesplit[1];
        console.log(hour, minute);
        var Time =
          (hour % 12) +
          12 * (hour % 12 == 0) +
          ":" +
          minute +
          " " +
          (hour < 12 ? "AM" : "PM");
      }
      const date = dateRef.current.valueAsDate;
      const d = new Date(date);
      const dates = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/classes/update`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: classData.id,
            schedule: {
              date: dates,
              time: Time,
            },
          }),
        }
      );
      const jsonData = await response.json();
      if (response.status === 200) {
        setOpen(true);
        setTmsg({ status: "Success", message: jsonData.message });
      } else {
        setOpen(true);
        setTmsg({
          status: "Failed",
          message: jsonData.message || "something went to wrong",
        });
      }
      popupDown();
    } else {
      setOpen(true);
      setTmsg({ status: "Failed", message: "Please fill all the fields" });
    }
  };

  return (
    <div className="create-class_container">
      <Toast open={open} msg={tmsg} setOpen={setOpen} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Popup
            close={popupDown}
            heading={
              isId
                ? classData.type === 0
                  ? "One to One"
                  : classData.type === 1
                  ? "One to six"
                  : classData.type === 2
                  ? "One to Many"
                  : classData.type === 3
                  ? "Demo Class"
                  : null
                : "Create Classroom"
            }
            refs={popupRef}
          >
            {isId ? (
              <div>
                <label>Schedule</label>
                <span
                  onClick={() => {
                    editmode ? setEditmode(false) : setEditmode(true);
                  }}
                  style={{
                    color: "red",
                    marginLeft: "2rem",
                    cursor: "pointer",
                  }}
                >
                  edit
                </span>
                {!editmode ? (
                  <div className="popup-details-card">
                    <span>{classData.date}</span>
                    <span style={{ marginLeft: "10px" }}>{classData.time}</span>
                  </div>
                ) : (
                  <div className="popup-details-card">
                    <label>Date</label>
                    <input
                      className="popup-input"
                      type="date"
                      ref={dateRef}
                      defaultValue={classData.date}
                    />
                    <label>Time</label>
                    <input
                      className="popup-input"
                      type="time"
                      ref={timeRef}
                      defaultValue={classData.time}
                    />
                    <button onClick={updateSchedule} className="popup-button">
                      Update
                    </button>
                  </div>
                )}
                <br />
                <label>Teacher</label>
                {classData.teacher?.length > 0 ? (
                  classData.teacher.map((data) => (
                    <p className="popup-details-card">{data.name}</p>
                  ))
                ) : (
                  <p className="popup-details-card">empty</p>
                )}
                <label>Students</label>
                {classData.students?.length > 0 ? (
                  classData.students.map((data) => (
                    <p className="popup-details-card">{data.name}</p>
                  ))
                ) : (
                  <p className="popup-details-card">empty</p>
                )}
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e, data)}>
                <div className="input-container">
                  <label>ClassRoom Name</label>
                  <select ref={typeRef}>
                    <option value="" selected disabled>
                      Select Classroom Type
                    </option>
                    <option value={0}>one to one</option>
                    <option value={1}>one to six</option>
                    <option value={2}>one to Many</option>
                    <option value={3}>Demo class</option>
                  </select>
                </div>
                <div className="input-container">
                  <label>Date</label>
                  <input ref={dateRef} type="date" />
                </div>
                <div className="input-container">
                  <label>Time</label>
                  <input ref={timeRef} type="time" />
                </div>
                <div className="input-container">
                  <label>subject</label>
                  <select ref={subjectsRef}>
                  <option disabled selected>Select Subject</option>
                    {subject && subject.map((sub) => (
                      
                      <option key={sub} value={sub}>{sub}</option>
                    ))}
                  </select>
                </div>
                <div className="input-container">
                  <label>Label</label>
                  <input type="text" ref={labelRef} />
                </div>
                <button className="btn-submit" type="submit">
                  {" "}
                  Create Classroom
                </button>
              </form>
            )}
          </Popup>

          <Room
            setCallAgain={setCallAgain}
            callAgain={callAgain}
            click={popupAir}
            head="Classroom"
            datas={classes}
          />
          <Room
            setCallAgain={setCallAgain}
            callAgain={callAgain}
            head="Students"
            datas={students}
          />
          <Room
            setCallAgain={setCallAgain}
            callAgain={callAgain}
            head="Teachers"
            datas={teachers}
          />
        </>
      )}
    </div>
  );
}
