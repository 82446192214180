import React, { useContext, useEffect, useState } from 'react'
import { EssentialContext } from '../../../Assets/EssentialContext'
import Button from '../../Button/Button'
import './BlogCard.scss'

export default function BlogCard({ category, id, title, body, image }) {
    const [description, setDescription] = useState(null)
    const [essential, setEssential] = useContext(EssentialContext)

    useEffect(() => {
        let desc;
        desc = body.replace(/[^A-Za-z0-9]/g, ' ')
        desc = desc.replace(/\s\s+/g, " ")
        setDescription(desc)
    }, [essential.removeCard])
    return (
        <>
            {
                essential.removeCard !== id &&
                <div className="blog-card" _id={id}>
                    <img src={image.image_url} alt="" />
                    <div className="card-body">
                        <p className="category">{category}</p>
                        <h3 className="heading">{title}</h3>
                        {
                            description &&
                            <p className="description">{description}</p>
                        }
                        <div className="blog-card-btns-wrapper">
                            <Button children="View Live" type="btn-success" />
                            <div className="edit-and-delete-wrapper">
                                <Button icon="edit" type="btn-primary btn-edit" _id={id} />
                                <Button icon="delete" type="btn-danger btn-delete" _id={id} image={image}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
