import React, { useEffect, useState } from "react";
import "./CreateBlog.scss";
import instance_url from "../../Assets/instance";
import axios from "axios";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import Message from "../message/Message";
import { useNavigate } from "react-router-dom";
import storage from "../../Assets/firebaseConfig";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

export default function CreateBlog() {
    const [imagePreview, setImagePreview] = useState("");
    const [formData, setFormData] = useState({
        body: "",
        title: "",
        category: "",
    }),
        [testData, setTestData] = useState(null),
        [msgDisplay, setMsgDisplay] = useState(null),
        [editMode, setEditMode] = useState(false);
    

    const mdParser = MarkdownIt();
    const navigate = useNavigate();

    //To check editing blog or create blog
    useEffect(() => {
        let url = window.location.href.split("/");

        if (url.length === 7) {
            let id = url[url.length - 1];
            getSingleBlog(id);
        }
    }, []);

    // On Editing a blog, For fetching the single blog data
    const getSingleBlog = async (id) => {
        await axios.get(`${instance_url}/blog/single/${id}`).then((res) => {
            console.log(res.data);
            if (res.data.status === "OK") {
                let { image, body, title, category, _id } = res.data.blog;
                setImagePreview(image.image_url);
                setFormData({ body, title, category, image, _id });
                setEditMode(true);
            }
        });
    };

    // To Handle image Upload
    const handleImageInput = (event) => {
        if (event.target.files.length > 0) {
            setImagePreview(URL.createObjectURL(event.target.files[0]));
            setTestData(event.target.files[0]);
        }
    };

    // function for handle the editor change md
    function handleEditorChange({ html, text }) {
        console.log(html,text)
        setFormData({ ...formData, body: text });
    }
    // Function for handle the change in the input
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    // Function for control the submission of the form
    const handleFormSubmit = async (event) => {
        event.preventDefault()
        let fileName = testData && new Date().toISOString() + testData.name;
        const token = localStorage.getItem("token");
        event.preventDefault();
        if (!formData.hasOwnProperty("category") || formData.category === "null") {
            const categoryLabel = document.querySelector(".category-label");
            categoryLabel.innerHTML = "Please select a category from below";
            categoryLabel.style.color = "red";
            window.scrollTo(0, 0);
        } else {

            if (editMode) {
                if(testData){
                    // console.log(formData.image);
                    const deleteRef = ref(storage,"images/"+ formData.image.image_name)
                    deleteObject(deleteRef).then(() => {
                        saveImage(fileName,token,editBlog)
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    editBlog(formData,token)
                }
            }else{
                saveImage(fileName,token,uploadBlog)
            }
            
            
        }
    };

    // Function for save image into firebase
    const saveImage = (fileName,token,uploadFunction) => {
        let data;
        const storageRef = ref(storage,"images/" + fileName );
            uploadBytes(storageRef, testData).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    data = {...formData,fileDestination:url,fileName:fileName}
                    uploadFunction(data,token)
                });
            });
    }

    // Function for upload data into server
    const uploadBlog = async(data,token) => {
        await axios.post(`${instance_url}/blog/`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            },
        }).then( res => {
            if(res.data.status === 'OK'){
                setMsgDisplay(res.data.message);
                setImagePreview('');
                setFormData({body:''})
                document.getElementById('create_blog_form').reset();

                setTimeout(()=>{
                    setMsgDisplay(null);
                },[3000])
            }
        })
        console.log(data)
    }

    // Function for edit data in server
    const editBlog = async(data,token) => {
        if(!testData){
            data = {...data,fileName: data.image.image_name,fileDestination: data.image.image_url}
        }
        await axios.patch(`${instance_url}/blog/`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        if(res.data.status){
                            setMsgDisplay("Blog Edited Sucessfully!")

                            setTimeout(()=>{
                                setMsgDisplay("")
                                navigate('/admin-panel/blog')
                            },[3000])
                        }
                    });
                return;
    }
    return (
        <form
            onSubmit={handleFormSubmit}
            className="form-create-blog"
            id="create_blog_form"
        >
            <h1>{editMode?'Edit':'Create'} Blog</h1>
            <div className="title-wrapper">
                <label htmlFor="title">Title</label>
                <input
                    type="text"
                    required
                    value={formData.title}
                    name="title"
                    onChange={handleInputChange}
                    className="title-input"
                />
            </div>
            <div className="category-and-thumbnail-wrapper">
                <div className="category-wrapper">
                    <label htmlFor="category" className="category-label">
                        Category
                    </label>
                    <select
                        name="category"
                        id=""
                        value={formData.category}
                        onChange={handleInputChange}
                    >
                        <option value="null">Select Category</option>
                        <option value="JEE">JEE</option>
                        <option value="NEET">NEET</option>
                        <option value="COMEDK">COMEDK</option>
                        <option value="KCET">KCET</option>
                        <option value="PUC">PUC</option>
                        <option value="AP EAPCET">AP EAPCET</option>
                        <option value="TS EAMCET">TS EAMCET</option>
                        <option value="KEAM">KEAM</option>
                        <option value="KCET">KCET</option>
                        <option value="CBSE">CBSE</option>
                        <option value="GUJCET">GUJCET</option>
                        <option value="VITEEE">VITEEE</option>
                        <option value="SRMJEEE">SRMJEEE</option>
                        <option value="BCECE">BCECE</option>
                        <option value="One-on-one Tution">One-on-one Tution</option>
                    </select>
                </div>
                <div className="image-upload-wrapper">
                    <label htmlFor="">
                        Upload a thumbnail(Thumbnail preview in the preview section at the
                        bottom)
                    </label>
                    <input
                        type="file"
                        required={editMode ? false : true}
                        className="image-upload"
                        onChange={handleImageInput}
                        accept="image/png,image/jpeg,image/jpg"
                        alt=""
                    />
                </div>
            </div>
            <img src={imagePreview} className="image-preview" alt="" />
            <div className="markdown-container">
                <label htmlFor="body">
                    Write the content of the blog.(Use markdown)
                </label>
                <MdEditor
                    value={formData.body}
                    style={{ height: "500px" }}
                    renderHTML={(text) => mdParser.render(text)}
                    onChange={handleEditorChange}
                />
            </div>
            <input
                type="submit"
                value={editMode ? "Edit" : "Create"}
                className="btn btn-create"
            />
            {msgDisplay && <Message message={msgDisplay} />}
        </form>
    );
}
