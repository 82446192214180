import React, { useEffect, useRef, useState } from 'react'
import { TiTick } from 'react-icons/ti'
import { AiOutlineClose } from 'react-icons/ai'
import Message from '../message/Message'
import './ScheduledMeetings.scss'
import Toast from '../Toast/Toast'
import Loading from '../LoadingComp/Loading'

export default function ScheduledMeetings({setCallAgain}) {
    const [data, setData] = useState([])
    const [subjects, setSubjects] = useState([])
    const [toastMessage, setToastMessage] = useState(null)
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const [tmsg, setTmsg] = useState({});
    const [teacherInfo, setTeacherInfo] = useState({
        id: '',
        subjects: []
    })
    const popupRef = useRef(null);

    var Tsubject = [  "Chemistry",
    "Physics",
    "Mathematics",
    "Biology",
    "Economics",
    "Accounts",
    "Bussiness Studies",
    "Arabic",
    "French",
    "Hindi",
    "Computer science",
    "Malayalam",
    "English",]

    const getScheduledMeetings = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teacher/scheduled-meeting`)
        const data = await response.json()
        console.log("te", data.scheduledMeetings.teacher)

        setData(data.scheduledMeetings)
        setLoading(false)
    }

    const rejectTeacher = async (data) => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teacher/reject-meeting`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
            })
        })
        const res = await response.json()

        if (res.status === 'OK') {
            getScheduledMeetings()
            setOpen(true)
            setTmsg({ status: "success", message: res.message })
        }
        else {
            setOpen(true)
            setTmsg({ status: "failed", message: res.message || "something went to wrong" })
        }
        setLoading(false)
    }

    console.log(teacherInfo)
    const updateTeacher = async (data) => {
        setLoading(true)
        console.log("data", data)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teacher/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
                subjects: data.subjects,
                salary: [
                    {
                        oneToOne: data.oneToOne
                    },
                    {
                        oneToSix: data.oneToSix
                    },
                    {
                        oneToMany: data.oneToMany
                    }
                ]
            })
        })
        const res = await response.json()
        if (res.status === 'OK') {
            getScheduledMeetings()
            setOpen(true)
            setTmsg({ status: "success", message: res.message })
            setCallAgain(true)
        }
        else {
            setOpen(true)
            setTmsg({ status: "failed", message: res.message || "something went to wrong" })
        }
        setLoading(false)
    }

    const handleApplicationStatus = (item, method) => {
        if (method === "approve") {
            popupRef.current.classList.add("popup--active")
            setTeacherInfo({
                id: item.id,
                subjects: []
            })
        }
        if (method === "deny") {
            window.confirm('Are you sure to Reject this Teacher ?') &&
                rejectTeacher(item)

        }
    }

    const closePopup = () => {
        popupRef.current.classList.remove("popup--active")
    }

    const handleTeacherInfo = (event) => {
        setTeacherInfo({ ...teacherInfo, [event.target.name]: event.target.value })
    }

    const handleSubjects = (event) => {

        var index = teacherInfo.subjects.findIndex(() => event.target.name === event.target.value);
        console.log(index)
        if (index === -1) {
            setTeacherInfo({ ...teacherInfo, subjects: [...teacherInfo.subjects, event.target.value] });
        } else {
            var newArray = teacherInfo.subjects;
            newArray.splice(index, 1);
            setTeacherInfo({ ...teacherInfo, subjects: newArray });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        updateTeacher(teacherInfo)

    }

    useEffect(() => {
        getScheduledMeetings()
    }, [])


    return (
        <div className="scheduled-meetings-container table-container">
           { loading ? <Loading/> :
           <>
            <Toast open={open} msg={tmsg} setOpen={setOpen} />
            <h1 className="section-title">Scheduled Meetings</h1>
            <table className="statics-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>ZoomID</th>
                        <th>Subjects</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data ?
                        data.map((item, index) => (
                            item.isVerified === 1 ?
                                <tr key={index} className="rows">
                                    <td>{item.name}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>{item.zoomid}</td>
                                    <td>
                                        {
                                            item.subjects.map((subject, index) => (
                                                <span key={index}>{subject} </span>
                                            ))
                                        }
                                    </td>

                                    <td>{item.date}</td>
                                    <td>{item.time}</td>

                                    <td>
                                        <button className="deny" onClick={() => handleApplicationStatus(item, 'deny')}>
                                            <AiOutlineClose />
                                        </button>
                                        <button className="approve" onClick={() => handleApplicationStatus(item, 'approve')}>
                                            <TiTick />
                                        </button>
                                    </td>
                                </tr>
                                : null
                        ))
                        : 'No Scheduled Meetings'}
                </tbody>
            </table>
            {toastMessage && <Message message={toastMessage} />}
            <div className="teacher-accept-popup-container" ref={popupRef}>
                <div className="teacher-accept-popup">
                    <button className="btn-close" onClick={closePopup}>
                        <AiOutlineClose />
                    </button>
                    <h2 className="popup-title">Update Teacher</h2>
                    <form className="schedule-form" onSubmit={handleSubmit}>
                        <label htmlFor="">Subjects</label>
                        <div className="subjects-wrapper">
                            {Tsubject && Tsubject.map((sub)=>(<label htmlFor={sub}>
                                <input type="checkbox" name='subjects' id={sub} value={sub} onChange={handleSubjects} />
                                <span>{sub}</span>
                            </label>))}
                           
                        </div>
                        <label htmlFor="date">Salary 1:1(Hourly)</label>
                        <input type="text" className="schedule-input" name='oneToOne' onChange={handleTeacherInfo} />
                        <label htmlFor="time">Salary 1:6(Hourly)</label>
                        <input type="text" className="schedule-input" name='oneToSix' onChange={handleTeacherInfo} />
                        <label htmlFor="time">Salary 1:Many(Per class)</label>
                        <input type="text" className="schedule-input" name='oneToMany' onChange={handleTeacherInfo} />
                        <button type='submit' className="btn-schedule">Update</button>
                    </form>
                </div>
            </div>
            </>}
        </div>
    )
}
