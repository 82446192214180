import React, { createContext, useState } from 'react'
import jwt from 'jsonwebtoken'
export const EssentialContext = createContext();

export const EssentialProvider = ({ children }) => {
    const [essential, setEssential] = useState({
        removeCard: null,
        notificationMessage: null,
        checkUser: (navigate) => {
            const token = localStorage.getItem('token')
            if (token) {
                jwt.verify(token, 'secret1234', function (err, decoded) {
                    if (err) {
                        console.log(err,"err")
                        localStorage.clear()
                        navigate('/revamp-adminiq-panel/dashboard')
                    }
                })
            } else {
                localStorage.clear()
                navigate('/revamp-adminiq-panel')
            }
        },
        logOut: (navigate) => {
            if (window.confirm('Are you sure want to Logout '))
                localStorage.clear()
            navigate('/revamp-adminiq-panel')
        }

    })

    return (
        <EssentialContext.Provider value={[essential, setEssential]}>
            {children}
        </EssentialContext.Provider>
    )
}
