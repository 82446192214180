import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCm770NhG_13WdJg0nQRbW185bONH2yiMI",
    authDomain: "revamp-24.firebaseapp.com",
    projectId: "revamp-24",
    storageBucket: "revamp-24.appspot.com",
    messagingSenderId: "171875748670",
    appId: "1:171875748670:web:e24e02a1f7625ad4033a6f",
    measurementId: "G-CKQ1V55JQF"
};
  
  // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);

export default storage;