import React from 'react'
import Card from '../Card/Card'
import './Room.scss'

export default function Room({ head, datas,click ,callAgain,setCallAgain}) {

 const whenDragover = (e) => {
    e.preventDefault()
  }

  return (
    <div  onDragOver={whenDragover} className="room_container">
      <h1 className="room-head">{head}</h1>

      <div className="room-cards">
        {datas && datas.map((items, index) => (
              <Card click={click} label={items.label} head={head} id={items.id} key={index} name={items.name ? items.name :items.type === 0 ? "one to one" : items.type === 1 ? "one to six" : items.type === 2 ? "one to many" : items.type ===3 ? "Demo Class " :items.type} />  
          ))}
      </div>
      {head === "Classroom" &&
        <button 
        onClick={() => { click("create") }} 
        className="btn">Create Classroom </button>}
    </div>
  )
}
