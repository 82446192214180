import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { EssentialContext } from '../../Assets/EssentialContext'
import instance_url from '../../Assets/instance'
import Button from '../Button/Button'
import Message from '../message/Message'
import './Blog.scss'
import BlogCard from './BlogCard/BlogCard'
import _ from 'lodash';

export default function Blog() {
    const [content, setContent] = useState(null)
    const [essential, setEssential] = useContext(EssentialContext)

    useEffect(() =>{
        axios.get(`${instance_url}/blog/`).then( res =>{
            setContent(res.data.blogs)
        })
    },[])

    return (
        <section className="blog-section">
            <div className="blog-create-btn-wrapper">
                <Button 
                    children="Create a new blog" 
                    type="btn-success"
                    icon="create"
                />
            </div>
            <div className="blog-cards-wrapper">
                {
                    content && 
                    content.map(item => (
                        <BlogCard 
                            title={item.title}
                            category={item.category}
                            body={item.body}
                            key={item._id}
                            id={item._id}
                            image={item.image}
                        />
                    ))
                }
            </div>
            {
                essential.notificationMessage&&
                <Message 
                    message={essential.notificationMessage}
                />
            }
        </section>
    )
}
