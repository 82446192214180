import React, { useEffect, useRef, useState } from 'react'
import { TiTick } from 'react-icons/ti'
import { AiOutlineClose } from 'react-icons/ai'
import Message from '../message/Message'
import './Students.scss'
import Loading from '../LoadingComp/Loading'

export default function Students() {
    const [data, setData] = useState([])
    const [subjects, setSubjects] = useState([])
    const [loading,setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState(null)
    const [teacherInfo, setTeacherInfo] = useState({
        id: '',
        subjects:[]
    })
    const popupRef = useRef(null);


    const getStudents = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/student`)
        const data = await response.json()
        console.log("te", data.students)
        setData(data.student)
        setLoading(false)
    }

    const rejectTeacher = async (data) => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/student/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
            })
        })
        const res = await response.json()

        if (res.status === 'OK') {
            getStudents()
        }
        setLoading(false)
    }

    const updateStudents = async (data) => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/student/update`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
                onetoOne:data.oneToOne,
                onetoSix:data.oneToOne,
                onetoMany:data.oneToOne,
            })
        })
        const res = await response.json()
        if (res.status === 'OK') {
            setLoading(false)
        }
        setLoading(false)
    }
                

   

    const handleApplicationStatus = (item, method) => {
        if (method === "approve") {
            popupRef.current.classList.add("popup--active")
            setTeacherInfo({
                id: item.id,
                subjects: []
            })
        }
        if (method === "deny") {
           window.confirm("Are you sure you want to Delete this Student?") && rejectTeacher(item)
        }
    }

    const closePopup = () => {
        popupRef.current.classList.remove("popup--active")
    }

    const handleTeacherInfo = (event) => {
        setTeacherInfo({ ...teacherInfo, [event.target.name]: event.target.value })
    }
    console.log(teacherInfo)
    // const handleSubjects = (event) => {
   
    //     var index = teacherInfo.subjects.findIndex( ()=> event.target.name === event.target.value);
    //     console.log(index)
    //     if (index === -1) {
    //         setTeacherInfo({ ...teacherInfo, subjects: [...teacherInfo.subjects, event.target.value] });
    //       } else {
    //         var newArray = teacherInfo.subjects;
    //         newArray.splice(index, 1);
    //         setTeacherInfo({ ...teacherInfo, subjects: newArray });
    //       }
    // }



    const handleSubmit = (event) => {
        event.preventDefault()
        updateStudents(teacherInfo)
    }

    useEffect(() => {
        getStudents()
    }, [])


    return (
        <div className="scheduled-meetings-container table-container">
            {
           loading ? <Loading/> :
           <>
            <h1 className="section-title">Students</h1>
            <table className="statics-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>email</th>
                        <th>Subjects</th>
                        <th>Class</th>
                        <th>Board</th>
                        <th>Guardian</th>
                        <th>Guardian Mobile</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data ?
                        data.map((item, index) => (



                            
                           
                                <tr key={index} className="rows">
                                    <td>{item.name}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {
                                            item.subjects.map((subject, index) => (
                                                <span key={index}>{subject} </span>
                                            ))
                                        }
                                    </td>

                                    <td>{item.class}</td>
                                    <td>{item.board}</td>
                                    <td>{item.guardian}</td>
                                    <td>{item.gmobile}</td>

                                    <td>
                                        <button className="deny" onClick={() => handleApplicationStatus(item, 'deny')}>
                                            <AiOutlineClose />
                                        </button>
                                        <button className="approve" onClick={() => handleApplicationStatus(item, 'approve')}>
                                            <TiTick />
                                        </button>
                                    </td>
                                </tr>
                              
                        ))
                    :'No Students'}
                </tbody>
            </table>
            {toastMessage && <Message message={toastMessage} />}
            <div className="teacher-accept-popup-container" ref={popupRef}>
                <div className="teacher-accept-popup">
                    <button className="btn-close" onClick={closePopup}>
                        <AiOutlineClose />
                    </button>
                    <h2 className="popup-title">Update Students</h2>
                    <form className="schedule-form" onSubmit={handleSubmit}>
                        {/* <label htmlFor="">Subjects</label>
                        <div className="subjects-wrapper">
                            <label htmlFor="biology">
                                <input type="checkbox" name='subjects' id='biology' value="biology" onChange={handleSubjects} />
                                <span>Biology</span>
                            </label>
                            <label htmlFor="physics">
                                <input type="checkbox" name='subjects' id='physics' value="physics" onChange={handleSubjects} />
                                <span>Physics</span>
                            </label>
                            <label htmlFor="chemistry">
                                <input type="checkbox" name='subjects' id='chemistry' value="chemistry" onChange={handleSubjects} />
                                <span>Chemistry</span>
                            </label>
                            <label htmlFor="maths">
                                <input type="checkbox" name='subjects' id='maths' value="maths" onChange={handleSubjects} />
                                <span>Maths</span>
                            </label>
                        </div> */}
                        <label htmlFor="date">Credit 1:1(Hourly)</label>
                        <input type="string" className="schedule-input" name='oneToOne' onChange={handleTeacherInfo} />
                        <label htmlFor="time">Credit 1:6(Hourly)</label>
                        <input type="string" className="schedule-input" name='oneToSix' onChange={handleTeacherInfo} />
                        <label htmlFor="time">Credit 1:Many(Per class)</label>
                        <input type="string" className="schedule-input" name='oneToMany' onChange={handleTeacherInfo} />
                        <button type='submit' className="btn-schedule">Update</button>
                    </form>
                </div>
            </div>
            </>}
        </div>
    )
}
