import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import instance_url from '../../Assets/instance';
import './Login.scss'
export default function Login() {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  function Checkuser() {
    if(localStorage.getItem('token')){
    const token = window.localStorage.getItem('token')
    console.log(token,"token")
    if (token) {
      navigate("/admin-panel/dashboard")
    }
  }else{
    console.log("hlllll")
  }
  }
  useEffect(() => {
    Checkuser()
  })

  async function adminLogin(event) {
    setLoading(true)
    event.preventDefault()
    console.log(instance_url);
    fetch(`${instance_url}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        password
      })
    }).then((res)=>res.json())
    .then((data)=>{
      console.log(data)
      if(data.status !== 'Failed'){
        window.localStorage.setItem('token', data.admin)
        navigate("/admin-panel")
      }
      setErrorMessage(data.message)
      setLoading(false)
    })
    .catch((err)=>{
      setErrorMessage(err)
    })
    } 
  

  //   async function registerAdmin(event) {
  //     event.preventDefault()
  //     await fetch('http://localhost:8080/api/revamp/authentication', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       name,
  //       password,
  //     }),
  //   })
  // }
 

  return (
    <div className="admin-login-wrapper">


      <div className="admin-container">
        <h1>Login</h1>

        {/* <form onSubmit={registerAdmin}>  */}
        <form onSubmit={adminLogin}>
          <div className="name">
            <input placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              value={name} type="text" name="name" />
          </div>
          <div className="password">
            <input placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} type="password" name="password" /><br />
          </div>


          <button  type="submit" value="Login">
            {
              loading ? <img src="images/loading.gif" alt="" /> : "Login"
            }
          </button>
        </form>
         <div className="error"> {errorMessage} </div>
      </div>
    </div>
  )
}
