import React, { useState } from "react";
import Toast from "../../Toast/Toast";
import "./Card.scss";

export default function Card({ label, name, id, click, head,callAgain ,setCallAgain}) {
  const [open, setOpen] = useState(false);
  const [tmsg, setTmsg] = useState({});
  const whenDrag = (e) => {
    e.dataTransfer.setData("card", e.target.id);
    console.log(e.target.id);
  };

  const addClassdata = async (classtypeid, id, type) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/classes/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          typeid: classtypeid,
          id: id,
          type: type,
        }),
      }
    );
    const data = await response.json();
    setOpen(true)
    setTmsg(data)
    // setCallAgain(!callAgain)
  };

  const whenDrop = (e) => {
    e.preventDefault();
    let cardname = e.target.closest(".card_title");
    var data = e.dataTransfer.getData("card");
    const classtypeID = e.target.id;
   
    let type = cardname?.textContent == "one to one" ? 1 : cardname?.textContent == "one to six" ? 2 : cardname?.textContent == "one to many" ? 3 : null;
    console.log(classtypeID,"Class id" ,cardname)
    addClassdata(classtypeID, data, type);
  };

  return (
    <>
      <Toast open={open} msg={tmsg} setOpen={setOpen} />
      {head === "Classroom" ? (
        <div
          onClick={() => {
            console.log("clicked");
            click("create", id);
          }}
          id={id}
          onDrop={whenDrop}
          className="card_container"
        >
        {label && <h3 id={id}  style={{color:'green'}}>{label}</h3>}
          <p className="card_title" id={id}>{name}</p>
        </div>
      ) : (
        <div
          id={id}
          onDragStart={whenDrag}
          draggable="true"
          className="card_container"
        >
          <p>{name}</p>
        </div>
      )}
    </>
  );
}
