import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './TrialClass.scss'
import instance_url from '../../Assets/instance';
import { useNavigate } from 'react-router';
import Loading from '../LoadingComp/Loading';

const columns = [
   { field: 'child_name', headerName: 'Name', width: 130 },
   { field: 'mobile', headerName: 'Mobile', width: 130 },
   {
      field: 'grade',
      headerName: 'Grade',
      width: 90,
   },
   {
      field: 'have_laptop',
      headerName: 'Laptop',
      width: 160,
      valueGetter: (params) =>
         params.value ? 'Yes' : 'No'
   },
   {
      field: 'date',
      headerName: 'Date',
      width: 200,
      valueGetter: (params) =>
         new Date(params.value).toLocaleDateString()
   },
   {
      field: 'status',
      headerName: 'Status',
   }
];


export default function TrialClass() {
   const [rows, setRows] = useState(null)
   const [confirm, setConfirm] = useState('')
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate()
   const getTrialClass = async () => {
      setLoading(true)
      const token =localStorage.getItem('token')
      const TrialResponse = await fetch(`${instance_url}/trialclass`,
      {
         method: 'GET',
         headers: {
            'Authorization':'Bearer '+token
         }

      })
      const data = await TrialResponse.json()
      setRows(data.data)
      console.log(data.status)
      if (data.status === 'FAILED'){
         localStorage.clear()
         navigate('/revamp-adminiq-panel')
      }
      setLoading(false)
   }
   const confirmBooking = async (event) => {
      setLoading(true)
      const token =localStorage.getItem('token')
      const name = event.row.child_name
      if (window.confirm('Are you sure to Change The Booking Status of  ' + name)) {
         let status = event.row.status
         let id = event.id
         console.log(status, id)
         const leadResponse = await fetch(`${instance_url}/trialclass`, {
            method: 'PATCH',
            headers: {
               'Content-Type': 'application/json',
               'Authorization':'Bearer '+token
            },
            body: JSON.stringify({
               status: status === 'Completed' ? 'Booked' : 'Completed',
               id
            })
         })
         const leadResult = await leadResponse.json()
         console.log(leadResult)
         event.row.status = status === 'Completed' ? 'Booked' : 'Completed'
         setConfirm(status)
      }
      setLoading(false) 
   }
   useEffect(() => { getTrialClass() }, [])
   return (
      <>
         {
            
         rows&&
         <div className="trial-class-container">
            { loading ? <Loading/> :
           <>
            <div style={{ height: '100%', width: '100%' }}>
               <DataGrid
                  rows={rows}
                  onCellDoubleClick={confirmBooking}
                  columns={columns}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
         
               />
            </div>
            </>}
         </div>
         
      }

      </>
   );
}

